
import {defineComponent, onMounted, ref, watch} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import EditTransactionModal from "@/components/modals/forms/EditTransactionModal.vue";
import {MenuComponent} from "@/assets/ts/components";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {useRoute} from "vue-router";
import {IProduct} from "@/views/apps/products/ProductsListing.vue";
import {IStock} from "@/views/apps/stocks/StocksListing.vue";
import dayjs from'dayjs'

interface ITransaction {
  ID: number;
  Product: IProduct;
  Stock: IStock;
  quantity: number;
  updated_at: string;
}

export default defineComponent({
  name: "transactions-listing",
  components: {
    Datatable,
    EditTransactionModal
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const checkedTransactions = ref([]);
    const tableHeader = ref([
      /*{
        key: "checkbox",
      },*/
      {
        name: "Product",
        key: "product-title",
        sortable: false
      },
      {
        name: "Stock",
        key: "stock-title",
        sortable: false
      },
      {
        name: "Quantity",
        key: "quantity",
        sortable: true
      },
      {
        name: "Date",
        key: "UpdatedAt",
        sortable: true
      },
      {
        name: "Actions",
        key: "actions",
        sortable: false
      },
    ]);
    const tableData = ref<Array<ITransaction>>([]);
    const initTransactions = ref<Array<ITransaction>>([]);
    const isAdmin = ref<boolean>(false);
    const loading = ref<boolean>(false);

    const getUser = () => {
      store.dispatch(Actions.USER_PROFILE)
          .then(({data}) => {
            isAdmin.value = data.role == "admin"
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    const fetchData = () => {
      loading.value = true

      store.dispatch(Actions.TRANSACTIONS_LIST, [route.params.section + '/' + route.params.id])
          .then(({data}) => {
            tableData.value = data
            loading.value = false
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    onMounted(() => {
      fetchData()
      getUser()
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Transactions Listing", ["Apps", "Transactions"]);
    });

    watch(() => tableData.value, first => {
      initTransactions.value.splice(0, initTransactions.value.length, ...first);
    });

    const selectedTransaction = ref<ITransaction>()

    const selectTransaction = item => {
      selectedTransaction.value = item
    }

    const deleteTransaction = id => {
      store.dispatch(Actions.TRANSACTION_DELETE, id)
          .then(() => {
            for (let i = 0; i < tableData.value.length; i++) {
              if (tableData.value[i].ID === id) {
                tableData.value.splice(i, 1);
              }
            }
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    };

    const deleteFewTransactions = () => {
      checkedTransactions.value.forEach((item) => {
        deleteTransaction(item);
      });
      checkedTransactions.value.length = 0;
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initTransactions.value);
      if (search.value !== "") {
        let results: Array<ITransaction> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          if (searchingFunc(obj[key], value)) {
            return true
          }
        }

        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const formatDate = (dateString): string => {
      const date = dayjs(dateString);
      return date.format('dddd MMMM D, YYYY');
    }

    return {
      tableData,
      tableHeader,
      deleteTransaction,
      search,
      searchItems,
      checkedTransactions,
      deleteFewTransactions,
      selectTransaction,
      selectedTransaction,
      isAdmin,
      loading,
      fetchData,
      formatDate
    };
  },
});

export { ITransaction };
